import axios from 'axios'

axios.defaults.withCredentials = true
if window?
  connect_addr = window.location.protocol + "//" +
    window.location.host
else
  connect_addr = 'http://127.0.0.1:5000'


export default {
  MAP_LINK: 'https://www.google.com/maps/place/%D0%AD%D0%BA%D1%80%D0%B0/@56' +
    '.1123827,47.2553083,17z/data=!3m1!4b1!4m5!3m4!1s0x415a37c18bb8cc43:0xb65' +
    'a4e10d4f467b5!8m2!3d56.1123827!4d47.257497'

  ROUTE_HOTEL_LINK: 'https://yandex.ru/maps/-/CCU5R6G1PA'

  HOTEL_LINK: 'https://hotel.ekra.ru'

  send_query: (query, path, method) ->
    query = query or {}
    path = path or 'api'
    method = method or 'POST'
    return axios({
      method: method
      headers: { 'content-type': 'application/json; charset=utf-8' }
      data: query
      url: connect_addr + '/' + path + '/'
      responseType: 'json'
    }).then((responce) ->
      return responce.data
    ).catch((error) ->
      console.error(error)
    )

}
