variables = {
  root_component: @
}

METRICS = {
  87452855: {
     clickmap: true,
     trackLinks: true,
     accurateTrackBounce: true,
     webvisor: true,
     defer: true
  }
  56850763: {
     clickmap: true,
     trackLinks: true,
     accurateTrackBounce: true,
     defer: true
  }
}

export default {
  variables: variables

  METRICS: METRICS

  move_to_path: (path, query) ->
    root_component = variables.root_component
    route = root_component.$route
    if Array.isArray(path)
      if path.length == 0
        path = ['', '']
      else if path[0] != ''
        path.unshift('')
      result = path.join('/')
    else
      result = path
    if result == route.path
      if not query?
        return
      else if JSON.stringify(query) == JSON.stringify(route.query)
        return
    # TODO or REMOVE save get params
    # if not query?
    #   query = route.query
    if window.ym?
      for index of METRICS
        ym(Number(index), 'hit', result)
    return root_component.$router.push({path: result, query: query})
}
