
import logo from './icons/logo.svg'
import phone_icon from './icons/phone.svg'
import geo_icon from './icons/geo.svg'
import mail_icon from './icons/mail.svg'
import window_helpers from './helpers/window.coffee'
import common_helpers from './helpers/common.coffee'

import background_1 from './assets/background_1.jpg'
import background_2 from './assets/background_2.jpg'
import background_3 from './assets/background_3.jpg'
import background_4 from './assets/background_4.jpg'
import background_5 from './assets/background_5.jpg'

export default {
  components: {
    logo: logo
  }

  data: () ->
    return {
      items: undefined
      is_ready: false
    }

  mounted: () ->
    window_helpers.variables.root_component = @
    @items = await common_helpers.send_query(null, 'data/events.json', 'GET')
    @is_ready = true

  methods: {
    _get_current_index: () ->
      keys = Object.keys(@items)
      if @course_name?
        return keys.indexOf(@course_name)
      else
        return -1

    move_to_home: () ->
      return window_helpers.move_to_path([])

    move_next: () ->
      keys = Object.keys(@items)
      index = @_get_current_index()
      if (index == -1) or ((index + 1) == keys.length)
        next = 0
      else
        next = index + 1
      window_helpers.move_to_path([keys[next]])

    move_previous: () ->
      keys = Object.keys(@items)
      index = @_get_current_index()
      if index in [-1, 0]
        next = keys.length - 1
      else
        next = index - 1
      window_helpers.move_to_path([keys[next]])
  }

  computed: {
    course_name: () ->
      if @$route.name == 'Invites'
        return @$route.params.id
      else
        return @$route.params.course

    is_invite: () ->
      return @$route.name == 'Invites'

    show_nav_buttons: () ->
      return @$route.name in ['Home', 'Courses']

    logo_link: () ->
      return logo

    blocks: () ->
      return {
        geo: {
          icon: geo_icon
          text: 'г. Чебоксары,'
          link: common_helpers.MAP_LINK
          handler: (event) ->
            window.open(common_helpers.MAP_LINK)
            event.stopPropagation()
            event.preventDefault()
        }
        phone: {
          icon: phone_icon
          text: '+7 (8352) 22-01-31'
          link: 'tel:+78352220131'
        }
        geo_last: {text: 'Проспект И. Я. Яковлева, дом 3'}
        mail: {
          icon: mail_icon
          text: 'training@ekra.ru'
          link: 'mailto:training@ekra.ru'
        }
      }
  }
}
