import * as vue_router from 'vue-router'

const router = vue_router.createRouter({
  history: vue_router.createWebHistory('/'),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "home" */ '../views/home.vue')
    }, {
      path: '/:course:',
      name: 'Courses',
      component: () => import(/* webpackChunkName: "course" */ '../views/course.vue')
    }, {
      path: '/invite/:id:',
      name: 'Invites',
      component: () => import(/* webpackChunkName: "invite" */ '../views/invite.vue')

    }
  ]
})

export default router
