import window_helpers from './helpers/window.coffee'


var METRICS = window_helpers.METRICS;

(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

for (var index in METRICS) {
  if (METRICS.hasOwnProperty(index)) {
    ym(Number(index), "init", METRICS[index])
  }
}
